import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";
import DownloadMoreRAM from "../../assets/AUGUST_11_2023Assets/DownloadMoreRAM.jpg";
import DunningKrugerGraph from "../../assets/AUGUST_11_2023Assets/DunningKrugerGraph.jpg";
import TechnologyLiteracyMeme from "../../assets/AUGUST_11_2023Assets/TechnologyLiteracyMeme.png";
import GoogleFuMeme from "../../assets/AUGUST_11_2023Assets/GoogleFuMeme.jpg";

function AUGUST_11_2023() {
  const blogTitle = "A Problematic Act of Abstraction";
  const blogSubtitle =
    "Technology literacy? It doesn't even matter what that means, you need it. Why digital literacy now is no longer a nerdy hobby or a niche skill, but a necessity for all.";
  const blogAuthor = "Andrew Rust";
  const datePublished = "August 11th, 2023";
  const shareLink =
    "https://blogs.bytesizelearning.org/the-problem-of-abstracting-technical-knowledge";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="STEM Education, Creativity" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
          <img
            className="rounded-lg my-4 max-w-md w-full h-auto"
            src={TechnologyLiteracyMeme}
            alt="Man using a telephone improperly"
          />
          <p className="mb-4">
            Do you consider yourself literate? Assuming reading literacy, then
            yes of course if you are comprehending this.
          </p>
          <p className="mb-4">
            But what about literacy with regard to other domains?
          </p>
          <p className="mb-4">
            In an age where technology infuses almost every aspect of our lives,
            one must ponder: How digitally literate am I? And what exactly
            constitutes a functional level of digital literacy? These questions
            aren't just a reflection on our tech savviness, but a critical
            factor determining our active participation in society.
          </p>
          <p className="mb-4">
            By this point, we are likely all aware of the non-linear
            relationship between our understanding of a topic and our confidence
            of our understanding. With regard to technology, digital skills, and
            computer know-how, this discrepancy can be partuclarly impactful,
            especially on those who choose and confide in a path of ignorance.
          </p>
          <p className="mb-4">
            Whether dismissed as a task for "another day", or simply considered
            the forte of younger generations, the importance of digital skills
            and computer literacy cannot be overstated. Maybe you've heard echos
            of sentiments among these lines or are guilty of these yourself:
          </p>
          <ul>
            <li>
              <em className="text-xl">
                I'll leave the technology stuff to the younger folks, they're
                naturally better at it anyway
              </em>
            </li>
            <li>
              <em className="text-xl">
                I'm more of a [insert-other-field-here] kind of person,
                computers and technology stuff aren't my thing
              </em>
            </li>
            <li>
              <em className="text-xl">
                Why learn any of it myself when I could just pay for it or have
                [tech-savvy acquaintance] help me with it?
              </em>
            </li>
          </ul>

          <p className="mb-4">
            While it's quite natural to think along these lines, there are
            profound implications that we often fail to consider. This article
            aims to illuminate this critical matter, encouraging you to revisit
            your perspective, and hopefully inspire a more thoughtful,
            deliberate interaction with the digital world.
          </p>
          <p className="mb-4">
            As a side note, digital literacy as I am referring to it here is a
            nebulously and dynamically-defined term, which is why I must speak
            in generalities here and avoid any "litmus tests" of computer
            knowledge. If you think you are technologically literate -{" "}
            <a
              href="https://en.wikipedia.org/wiki/Dunning%E2%80%93Kruger_effect"
              target="_blank"
              rel="noopener noreferrer">
              {" "}
              assume you are not
            </a>{" "}
            , otherwise you certainly are not. The point being that it is
            continual investment of your time to learn and apply technological
            knowledge regardless of your starting point.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={DunningKrugerGraph}
            alt="Graph of Dunning Kruger Effect"
          />
          <h3>Abstraction: The Problem</h3>
          <p className="mb-4">
            Perhaps you are familiar with the concept of{" "}
            <strong>abstraction</strong>, understood in this context as the
            process of considering something independently of its associations
            or tangible elements. It's akin to "black-box" thinking where we
            trust a process or a system without understanding its internal
            workings.
          </p>
          <p className="mb-4">
            For example: we enjoy fresh bread without knowing the deails of the
            bread-making process and we may drive our cars without understanding
            the intricacies of their maintenance. This isn't inherently
            problematic. Delegating tasks like changing oil to a car mechanic
            saves us time and often guarantees better results, albiet with
            dependance on going to the car shop every few months.
          </p>
          <p className="mb-4">
            However, when this principle of abstraction is applied to our
            digital lives, the stakes become much higher. Unlike other services
            we delegate, the rapid and extensive infiltration of technology into
            all aspects of society amplifies the effects of our ignorance. Every
            industry, every profession, and especially our personal lives are
            increasingly tethered to technology. The amenities it offers - from
            ordering food in advance to complex data analytics - often come at a
            steep unseen cost, a lack of literacy that costs those who choose to
            stay ignorant. We often place blind trust in digital products we
            purchase just as we might with a baker's bread. The key difference,
            however, is that we can judge bread by its taste and appearance with
            limited room for{" "}
            <a
              href="https://www.thespruceeats.com/sugar-in-bread-what-you-need-to-know-5083091"
              target="_blank"
              rel="noopener noreferrer">
              fooling our judgement
            </a>{" "}
            . With digital products though, we lack the expertise and time to
            assess the value or potential threats of what we are getting,
            leading to an inflated trust in technological abstractions.
          </p>
          <p className="mb-4">
            It might be clear at first that we sacrifice money and time to
            embrace these conveniences, which are significant but not a foriegn
            sacrifice when it comes to abstraction. But in our blissful
            ignorance we fail to realize the scope and pace at which we
            jeopardize much more than that. Entire tech-based sub-industries
            have emerged out of ignorance about the workings of technology, and
            the internet has been a lucrative space to capitalize on this
            ignorance financially. From{" "}
            <a
              href="https://www.eff.org/issues/privacy"
              target="_blank"
              rel="noopener noreferrer">
              data privacy
            </a>{" "}
            to informed political discourse there is much more at stake when we
            treat modern technology as a black box (one that is metaphorically
            expanding in size to beyond our view of even the inputs/outputs).
          </p>
          <p className="mb-4">
            The urgency of literacy among the general public cannot be
            understated, as the problems triggered by a technologically
            illiterate society greatly overshadow the issues we would face if
            only a handful knew how to bake bread.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={DownloadMoreRAM}
            alt="Meme about Downloading More RAM"
          />
          <h3>Why Should You Care? - Some Obvious Reasons</h3>
          <p className="mb-4">
            Aside from the principal of living in ignorance vs understanding,
            Here are three ubiquitous categories where choosing technologial
            ignorance hurts you. On the surface these are quite obvious, but the
            depth of their impact each deserve a manuscript of their own.
          </p>

          <h3>Money</h3>
          <p className="mb-4">
            The first and often most compelling reason is financial. In an era
            where digital transactions are becoming the norm, understanding the
            nuances of online finance, fraud, and data protection are essential.
            The rise is digital currencies too have led to a wave of fraud like
            no other. The blind faith of trusting "crypto-bros" with your cash
            is a guaranteed fools errand, but lacking understanding of a concept
            as simple as encryption is a source of exploitation that
            cybercriminals exploit daily.
          </p>
          <p className="mb-4">
            Analogously, consider the costs of tech maintenance and repairs.
            Much like the disparity between DIY home repairs and hiring a
            professional, the markup in tech services can be staggering. A more
            fitting comparison might be the absurdity of purchasing a new home
            for a leaky faucet. This stark financial misjudgment springs from an
            inadequate frame of reference, leading many to shell out
            disproportionately for trivial or sometimes, non-existent fixes.
          </p>
          <p className="mb-4">
            The practical knowledge and confidence of deploying skills can save
            money on an recurring and potentially exponential scale. The
            examples range from debugging a password issue with your email to
            running your own little data center of your favorite movies,
            technology and a bit of creativity can save you a lot of money. More
            importantly, money is saved on what you realize(" ")
            <a
              href="https://www.ciodive.com/news/Flexera-report-wasted-tech-spend-ITAM/634944/"
              target="_blank"
              rel="noopener noreferrer">
              you don't need to pay for
            </a>
            , oftentimes we pay for technology services and software we don't
            know that we don't need.
          </p>
          <h3>Time</h3>

          <p className="mb-4">
            Our emphasis on time efficiency stems from the realization that
            while technology promises streamlined processes, the lack of
            foundational understanding often culminates in inefficiencies and
            wasted hours. Consider the explosion of "time-saving" project
            management tools and productivity apps. On the surface, they offer
            seamless task management. Yet, the time you expend on researching
            the right tool, setting it up, and learning its intricacies often
            exceeds the time it would have taken to grasp the foundational
            technology or principle in the first place.
          </p>
          <p className="mb-4">
            For instance, by just understanding the core principles of effective
            time management and basic digital processes, one could potentially
            leverage simple tools like spreadsheets, text documents, and
            calendar apps to craft a personalized solution, negating the need
            for pricier, more complex tools. This hands-on approach doesn't just
            save time and money but ensures adaptability without the hassles of
            migration or steep learning curves.
          </p>
          <p className="mb-4">
            Think about how an email vastly outpaces a traditional letter.
            Similarly, digital tools can expedite everything from bill payments
            to appointment bookings - this promise of technology is one that I
            don't disagree with. However, the actualization of this potential
            hinges on a solid foundational understanding of what we are trying
            to improve or optimize.
          </p>
          <p className="mb-4">
            Lastly, the art of efficient online searching, once dubbed
            "Google-Fu" and now evolving into "
            <a
              href="https://www.youtube.com/watch?v=H1sXIUbpRCU"
              target="_blank"
              rel="noopener noreferrer">
              proompting
            </a>{" "}
            " (ChatGPT and the like), epitomizes time-savviness. Perfecting this
            skill can drastically cut down research time, making minutes, not
            hours, the standard. The overarching theme is clear: a little
            upfront investment in digital literacy reaps substantial time-saving
            benefits down the line.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={GoogleFuMeme}
            alt="IT Crowd Meme about Google Fu"
          />
          <strong>
            Speaking of proompting, here is a GPT4 rendition of my paragraph on
            wellbeing...
          </strong>
          <div class="p-6 bg-gray-100 text-gray-600 rounded-lg shadow-md">
            <div class="font-bold text-xl mb-2">### Wellbeing</div>

            <div class="mb-4 p-4 ">
              Our holistic wellbeing—spanning our mental, emotional, and
              physical states—is deeply intertwined with our digital
              interactions in this tech-centric era. As the digital world offers
              us boundless information, it also overwhelms us with its sheer
              volume. Distinguishing credible sources from misleading ones
              becomes paramount; otherwise, we risk anxiety and misguided
              decisions.
            </div>

            <div class="mb-4 p-4 ">
              Consider the health sector: littered with online myths, only those
              adept in digital literacy can sift genuine advice from falsehoods.
              Social media, while promising connection, can be a quagmire
              without proper digital navigation. A lack of literacy exposes one
              to cyberbullying, privacy infringements, and the mental strain of
              unattainable online ideals. Contrast someone who curates their
              online experience judiciously against another buffeted by a
              relentless wave of digital perfection—the former finds joy while
              the latter might grapple with emotional turmoil.
            </div>

            <div class="mb-4 p-4 ">
              Think of your personal data as gold in the vast digital
              marketplace. Just as misplaced gold can invite theft, mishandling
              personal data can breach your privacy. Boosting our digital
              literacy is akin to bolstering the security of our treasure.
            </div>

            <div class="p-4 ">
              Furthermore, envision digital wellness as maintaining a balanced
              diet. Excessive or misguided tech consumption parallels a diet of
              junk, leading to ailments like eye strain or mental duress. In
              essence, as we sail the vast digital oceans, our wellbeing is our
              compass. Equipping ourselves with digital literacy ensures we're
              not set adrift, but instead, find our desired shores in this
              expansive digital realm.
            </div>
          </div>

          <strong>Not bad. Back to me to finish it out, thanks ChatGPT</strong>
          <h3>Conclusion: Supremely Scalable Skills</h3>
          <p className="mb-4">
            Unlike other domains, digital skills compound. As you learn how one
            technology works, you inadvertedly learn a bit about a handful of
            others. These skills scale to a significant extent making it a great
            investment in every sense of the word.
          </p>
          <p className="mb-4">
            In my view, digital literacy in all its meanings and forms is no
            longer a luxury or a domain exclusive to the technologically
            inclined. It is fast becoming a basic survival skill in an
            increasingly digital world. The benefits are tangible and immediate:
            saving money, improving efficiency, and safeguarding our personal
            wellbeing - each of which represent a inumerable host of hidden
            impacts that tech literacy gives us the ability to see.
          </p>
          <p className="mb-4">
            We can better protect our data, use technology to our advantage, and
            influence the discourse around its regulation and development. The
            inititive for change falls on our own shoulders. This is an avenue
            in which you alone can make change that affects you alone - no need
            to assemble or rally the troops. This is done by you, for you, and
            as a bonus, the betterment of all.
          </p>
          <p className="mb-4">
            Embracing the digital world isn't just about convenience; it's about
            crafting a future where we're not just passive users but empowered
            digital citizens. After all, technology is ever-evolving, and our
            knowledge should mirror its pace.
          </p>
          <p className="mb-4">
            If you enjoyed this article or want to talk more about it. Connect
            with me via {" "}
            <a
              href="mailto:andrew@bytesizelearning.org"
              target="_blank"
              rel="noopener noreferrer">
              email
            </a>{" "}
            or{" "}
            <a
              href="https://www.linkedin.com/in/andrew-rust30/"
              target="_blank"
              rel="noopener noreferrer">
              LinkedIn
            </a>
            . Thanks for reading.
          </p>
        </body>
      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default AUGUST_11_2023;
