import React from "react";
import AndrewAvatar from "../../assets/AndrewAvatar.jpg";
import ChristianAvatar from "../../assets/ChristianAvatar.png";
import ByteSizeAvatar from "../../assets/bytesize-logo.png";
function BlogCTACard(props) {
  const {
    blogTitle,
    blogSubtitle,
    blogAuthor,
    datePublished,
    shareLink,
    blogTags,
  } = props;

  const blogTagColorMapping = {
    Technology: "bg-purple-100 text-purple-800",
    "K-12 Education": "bg-yellow-100 text-yellow-800",
    "Higher Education": "bg-orange-100 text-orange-800",
    "STEM Education": "bg-pink-100 text-pink-800",
    Coding: "bg-green-100 text-green-800",
    Gaming: "bg-red-100 text-red-800",
    Creativity: "bg-orange-100 text-orange-800",
    "Artificial Intelligence": "bg-emerald-100 text-emerald-800",
    Cybersecurity: "bg-gray-100 text-gray-800",
  };

  let authorAvatar;

  if (blogAuthor === "Andrew Rust") {
    authorAvatar = AndrewAvatar;
  } else if (blogAuthor === "Christian Rust") {
    authorAvatar = ChristianAvatar;
  } else {
    authorAvatar = ByteSizeAvatar;
  }

  return (
    <div id="march31_2023-cta" className="h-full overflow-hidden">
      <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
        <div className="flex justify-between items-center mb-5 text-gray-500">
          <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
            {blogTags.map((tag) => {
              return (
                <span
                  className={
                    blogTagColorMapping[tag] +
                    " text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800"
                  }
                >
                  {tag}
                </span>
              );
            })}
          </span>
          <span className="text-sm">{datePublished}</span>
        </div>
        <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          <a href={shareLink}>{blogTitle}</a>
        </h2>
        <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
          {blogSubtitle}
        </p>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <img
              className="w-7 h-7 rounded-full"
              src={authorAvatar}
              alt="ByteSize Blogger - Author Avatar"
            />
            <span className="font-medium dark:text-white">{blogAuthor}</span>
          </div>
          <a
            href={shareLink}
            className="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline"
          >
            Read the Article!
            <svg
              className="ml-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </article>
    </div>
  );
}

export default BlogCTACard;
