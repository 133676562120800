import React from "react";
import AndrewAvatar from "../../assets/AndrewAvatar.jpg";
import ChristianAvatar from "../../assets/ChristianAvatar.png";
import ByteSizeAvatar from "../../assets/bytesize-logo.png";
import SharingBanner from "./SharingBanner";

function BlogHeader(props) {

    const { blogTitle, blogSubtitle, datePublished, shareLink, blogAuthor } = props;

    //conditional if the Author is Andrew Rust or Christian Rust, then display the appropriate avatar
    let authorAvatar;

    if (blogAuthor === "Andrew Rust") {
        authorAvatar = AndrewAvatar;
    } else if (blogAuthor === "Christian Rust") {
        authorAvatar = ChristianAvatar;
    } else {
        authorAvatar = ByteSizeAvatar;
    }

    return (
        <>
            <div id="blog-header">
                <header className="py-12 flex items-center">
                    <div className="px-4 w-full max-w-screen-xl text-center">
                        <span className="block mb-4 font-normal text-gray-500 dark:text-white">
                            <strong>Published on:</strong>{" "} {datePublished}
                        </span>
                        <h1 className="mx-auto mb-4 max-w-2xl text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
                            {blogTitle}
                        </h1>
                        <p className="text-lg text-gray-500 dark:text-gray-400 max-w-2xl">
                            {blogSubtitle}
                        </p>
                        <div className="flex items-center justify-center space-x-4 py-6">
                            <img className="w-7 h-7 rounded-full" src={authorAvatar} alt="Andrew Rust of ByteSize Learning" />
                            <span className="font-medium dark:text-white">
                                {blogAuthor}
                            </span>
                        </div>
                    </div>
                </header>
            </div>
            <SharingBanner datePublished={datePublished} shareLink={shareLink} />
        </>
    );
}

export default BlogHeader;
