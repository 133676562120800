import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";

function TEMPLATE() {
  const blogTitle = "The Critical Role of FLOSS in Educational Technology: Fostering Innovation, Security, and Access";
  const blogSubtitle = "What is FLOSS Software and what does it have to do with education? As it turns out, quite a lot actually";
  const blogAuthor = "Andrew Rust";
  const datePublished = "February 15th, 2024";
  const shareLink = "https://blogs.bytesizelearning.org/floss-in-technology-education";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta name="keywords" content="STEM Education, Technology, FLOSS" />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
          <p className="mb-4"><em>no, I am not talking about flossing your teeth, although you should be doing that too</em></p>
          <p className="mb-4"><strong>FLOSS:</strong> Free, Libre, Open Source, Software</p>
          <p className="mb-4"><em>Distinct from mere 'open source', FLOSS means users exert collective control, programmer or not - see <a href="https://en.wikipedia.org/wiki/The_Free_Software_Definition">these four essential freedoms</a>. Without these freedoms, it's just 'source available', not 'libre'.</em></p>
          <p className="mb-4">While there is room for a more meta-discussion around libre software, a good first place to us begin is within the landscape of educational technology: tools, platforms, devices used to teach or for teaching(including LMSs and any logistical tools that educators use). Here, I will advocate for <em>libre</em>, not just <em>free</em> and <strong>especially</strong> not closed-source educational software and tooling. Educational institutions from kindergarten through med school teaching everything from math to machine learning all continue to adopt software technology(rapidly, I might add) but it is important that intention goes into <em>what</em> software is used and taught.</p>
          <p className="mb-4">To summarize a magnitude of larger reasons into a digestible few, here are just three big reasons that ALL educational software should be FLOSS, or in a word: <em>libre</em>.</p>
          <ol>
            <li className="mb-2">Closed-source software stifles creativity and innovation - it limits community-driven development and the important 'tinkering' via which many students learn.</li>
            <li className="mb-2">Proprietary software often harbors security risks - it might covertly gather data on users (students, in this context) without their informed consent. Plus, open-source software benefits from the collective scrutiny of the global programming community, enhancing performance and security - paradoxically to many non-programmers.</li>
            <li className="mb-2">Libre software combats obsolescence and promotes lifetime accessibilty and financial affordability - in a domain where knowledge should be democratised, not monetized.</li>
          </ol>
          <h3>Section 1: Curiosity and Tinkering as a Bedrock of Learning</h3>
          <p className="mb-4">At the heart of all software lies a fundamental concept: programs are essentially sets of instructions that direct computers to perform specific tasks, from powering websites and educational tools to grading systems. Understanding this, the role of Free, Libre, Open Source Software (FLOSS) becomes pivotal in educational settings by empowering users with the freedom to modify, redistribute, and perpetuate improvements or modifications, providing a level of transparency that improves eduational outcomes for students, and moves the software world forward compared to any closed-source counterparts.</p>
          <p className="mb-4">The inherent curiosity of students, particularly those delving into computer sciences, serves as a critical learning catalyst. This natural inquisitiveness about the inner workings of daily-used software is a trait that educational environments should not only encourage but actively nurture. By providing access to the source code of their tools, educators can transform passive users into active learners and innovators. The potential risks associated with tinkering are far outweighed by the dangers of ignorance and opacity (see Section 2). Understanding and modifying source code equips students with hands-on, practical knowledge that often eludes traditional academic settings. </p>
          <p className="mb-4">Adopting FLOSS in education transcends mere preference—it's about establishing a fundamental principle. By integrating community-driven FLOSS projects into the curriculum, educational institutions can foster a generation that not only benefits from open-source ecosystems but also contributes to their growth. This philosophy mirrors the stewardship we ought to extend towards our planet: nurturing and preserving it for future generations.</p>
          <p className="mb-4">The reliance on proprietary, often costly, software in educational contexts does a disservice to students, tethering them to restrictive platforms, harming them financially in the long run and neutering their creative potential. Just to outline some alternatives to commonly taught software and shed light on rockstars in the FLOSS space, here are some long-standing, robust pieces of FLOSS software:</p>
          <ul>
            <li className="mb-2"><strong>GIMP(1998)</strong> - Photo/Image Editing</li>
            <li className="mb-2"><strong>Blender(1994)</strong> - 3D Modeling/Animation</li>
            <li className="mb-2"><strong>Audacity(2000)</strong> - Audio Editing</li>
            <li className="mb-2"><strong>Godot(2014)</strong> - Game Design </li>
            <li className="mb-2"><strong>Kdenlive(2002)</strong> - Video Editing</li>
          </ul>
          <p className="mb-4">The challenge of proprietary systems extends to operating systems as well, with GNU/Linux standing as a beacon of freedom, offering a wide array of FLOSS options catering to users from novices to experts. Educational institutions have a responsibility to go beyond mere software teaching; they should aim to equip students with a comprehensive skill set that includes basic scripting, networking, digital literacy, web development, and the critical ability to engage with documentation. It's not about creating students who are all software engineers, but instead citizens with a fundamental understanding of how a world built by, and dependant on technology works. </p>
          <h3>Section 2: Better Security & Privacy with FLOSS Educational Platforms</h3>
          <p className="mb-4"><em>The Myth of Security Through Obscurity: A Closer Look</em></p>
          <p className="mb-4">The conventional wisdom that closed-source software is inherently more secure because its inner workings are hidden is a myth that deserves debunking. This notion of <em>security through obscurity</em> is widely criticized among information security professionals, who argue that, in practice, transparency generally always leads to more robust security. Open-source projects benefit from the collective expertise of a global community of developers, including security experts who are more inclined to fix vulnerabilities than exploit them. This collaborative approach contrasts sharply with the limited oversight within closed-source projects, which often rely on a smaller team focused on meeting product deadlines rather than ensuring software integrity.</p>
          <p className="mb-4">Data collection about users is not inherently evil, but can quickly begin to appear so if the amount and nature of data being collected is unknown. Proprietary software's tendency to collect data without transparency poses a significant risk of non-consensual data exfiltration. Unlike closed-source platforms, where everything is a company secret, FLOSS projects prioritize user control over privacy settings. This user-centric approach ensures any form of telemetry or data collection is optional and transparent, aligning with the educational mandate to protect student privacy.</p>
          <p className="mb-4">Critics often cite the protection of "business secrets" as a reason to avoid open-sourcing software. However, most educational tools and platforms are not groundbreaking in their technical implementation but rather in how they're applied in an educational context. The fear that open-sourcing could lead to intellectual property theft overlooks the technical agnosticism on the business value as well as the viable legal protections provided by licenses such as the AGPL, which prevent unauthorized commercial use of community-contributed code. Especially when the end goal is education, choices of technology and implementation rarely, if ever, have any appreciable "secrets" worth protecting - most are electron-based CRUD (Create, Read, Update, Delete) applications where there is truly nothing new under the sun.</p>
          <p className="mb-4">In the educational sector, where the goal is to foster learning and innovation, it's vital to prioritize the collective advancement of knowledge over proprietary interests. Encouraging students to engage with, audit, and contribute to open-source projects not only enhances their learning experience but also improves the software they use. This openness fosters a culture of continuous improvement and accountability, essential qualities in the development of secure, effective educational technologies.</p>
          <h3>Section 3: Promoting Financial Accessibility Beyond The Classroom</h3>
          <p className="mb-4"><em>A Sustainable Approach to Technology in Education</em></p>
          <p className="mb-4">The specter of obsolescence looms large over proprietary software, threatening the longevity and relevance of the skills it teaches. Open-source software, in stark contrast, boasts an enduring presence. Consider Blender: a stalwart since 1994, it exemplifies the resilience of open-source projects. Such software remains accessible and updatable, sidestepping the pitfalls of discontinuation that often plague proprietary tools. This permanence ensures that the knowledge and skills acquired through FLOSS are lifelong assets for students, empowering them to troubleshoot and enhance the tools they rely on — an essential practice for budding engineers.</p>
          <p className="mb-4">The argument for FLOSS extends beyond the philosophical to the practical, especially when considering financial implications for students and educational institutions. By integrating tools like GIMP, Blender, or Godot into educational frameworks, institutions remove economic barriers, making high-quality education accessible to all, regardless of financial status. This approach democratizes learning, ensuring that anyone, anywhere can harness the full potential of digital education. It's a step towards fulfilling the internet's original promise of democratized access to knowledge, offering a robust foundation for learners across the globe.</p>
          <p className="mb-4">Embracing FLOSS in education, both institutionally and with its tooling, is more than a technological choice; it's a commitment to fostering a learning environment that is innovative, secure, and accessible. This model champions a more inclusive, equitable technological landscape, preparing students for a future where technology enhances collective understanding and progress. In doing so, it ensures that educational technology remains a vibrant, evolving field that reflects the diversity and dynamism of its user base, fostering a global community of learners equipped to navigate and shape the digital world.</p>
            <p className="mb-4"> If you enjoyed this, consider following along with future posts via our{" "}<a href="https://blogs.bytesizelearning.org/rss.xml">RSS Feed</a>, and feel free to connect with me over{" "}<a href="mailto:andrew@bytesizelearning.org">email</a> or{" "} <a href="https://www.linkedin.com/in/andrew-rust30/">LinkedIn</a> - Thanks for reading as always.
          </p>              
          </body>     
          </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default TEMPLATE;
