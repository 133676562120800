import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";

import SocratesMeme from "../../assets/APRIL_7_2023Assets/SocratesMeme.jpg";
import HonestWorkMeme from "../../assets/APRIL_7_2023Assets/HonestWorkMeme.jpg";
import EducationSystemMeme from "../../assets/APRIL_7_2023Assets/EducationSystemMeme.webp";
import BoardRoomSchoolMeme from "../../assets/APRIL_7_2023Assets/BoardroomSchoolMeme.jpg";
import STEMMeme from "../../assets/APRIL_7_2023Assets/STEMmeme.jpg";
import WeWantAnswersMeme from "../../assets/APRIL_7_2023Assets/WeWantAnswersMeme.png";
import MathMeme from "../../assets/APRIL_7_2023Assets/MathMeme.jpg";
// import FacebookArguingMeme from "../../assets/APRIL_7_2023Assets/FacebookArguingMeme.png";

import EducationPurposeThumbnail from "../../assets/EducationPurposeThumbnail.png";

function APRIL_7_2023() {
  const blogTitle = "What is the point of Education?";
  const blogSubtitle = "Why did education exist in the first place? What is the point of it anyway? Let's dig into the functions of education and see what we can find.";
  const blogAuthor = "Andrew Rust";
  const datePublished = "April 7th, 2023";
  const shareLink = "https://blogs.bytesizelearning.org/what-is-the-purpose-of-education-in-2023";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta
          name="keywords"
          content="K-12 Education, STEM Education, Creativity"
        />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
        <meta property="og:image" content={EducationPurposeThumbnail} />
      </Helmet>

      <BlogHeader
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        datePublished={datePublished}
        shareLink={shareLink}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
            <em className="text-xl">(Is it a simple answer?)</em>
          <p className="mb-4">
            What is the purpose of education? Why do we bother to learn anything
            at all?
          </p>
          <p className="mb-4">
            Is that a silly question? Well it might be, but if the answer is
            simple, maybe we are thinking about it all wrong.
          </p>
          <h2>Introduction: Establish Constants</h2>
          <p className="mb-4">
            Let's first define what we say when we refer to “education”. For
            this discussion, we are looking at the entirety of structured
            education as a collective, which includes K-12 schools and
            universities alike, public and private. Even homeschooling falls
            into this category.
          </p>
          <p className="mb-4">
            In a different category we might put the remaining extracurricular
            education like online masterclasses, educational blogs like this
            one, and science videos on YouTube. These tend to be more
            entertainment-focused and for “fun” rather than what we consider
            standardized, or more traditional education.
          </p>
          <p className="mb-4">
            Okay, as we have defined it, what is the purpose of education?
          </p>
          <p className="mb-4">
            One answer might be that the purpose of education is to learn
            knowledge, skills, and values to be able to lead a productive and
            fulfilling life, generally through the acquisition of some career
            that allows you to be productive to society and allows you to find
            fulfillment, either in your work, or with the money you make at
            work.
          </p>
          <p className="mb-4">
            Seems easy enough, but it becomes less straightforward if we pull
            back the layers and look at the history, fundamental theory, and the
            functions of the education system we have today.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={SocratesMeme}
            alt="Socrates Meme about the philosophy of education and thinking"
          />
          <h2>History of Education's Telelogy: OG Philosophers</h2>
          <p className="mb-4">
            Word of the day: Telelogy: underlying purpose, explanation of its
            function to its end{" "}
            <a
              className="text-sm"
              href="https://www.merriam-webster.com/dictionary/teleology"
            >
              [source]
            </a>
          </p>
          <p className="mb-4">
            At the beginning of it all, we start with the philosophers of old:
            The original wise man, Socrates, who was the teacher of Plato, who
            later became the teacher of Aristotle. Collectively, this trio
            serves as a good starting point for this discussion on the
            Philosophy of Education. This trio is also responsible for many
            sleepy classrooms of high school and college students everywhere.
          </p>
          <p className="mb-4"></p>
          <p className="mb-4">
            Even so, they are considered the birthers of formal education,
            although their format was far from structured. Most aptly, it was a
            small group of people following these public figures around while
            they talked, debated, and thought deeply about the human condition -
            they questioned anything in an attempt to better understand
            everything. Socrates focused a lot on rhetoric, Plato on philosophy,
            and Aristotle on developing 'virutous citizens', although their
            teachings are mostly documented today through pseudo-motivational
            quotes that line the halls of the few philosophy departments that
            remain. They accomplished greater knowledge and awesome quotes by
            asking questions, engaging in discourse, and ultimately developing
            critical thinking skills in a social, almost debate-style, setting.
          </p>
          {/* <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={FacebookArguingMeme}
            alt="Facebook Meme about the philosophy of education and thinking"
          /> */}
          <p className="mb-4">
            The underlying purpose behind these early teachers, as it is best
            known, was to increase an understanding of the world around them, to
            question assumptions, and to think critically about society and the
            nature of the world
            <a
              className="text-sm"
              href="https://www.sciencedirect.com/science/article/pii/S1877042812020174"
            >
              [1]
            </a>
            . They had a very abstract pursuit of knowledge to try and
            understand how anything works or better yet, why? Secondarily, they
            were also interested in developing a better society through creating
            thoughtful citizens who engage ethically with the world around them
            and their fellow citizens
            <a
              className="text-sm"
              href="https://www.researchgate.net/publication/236896596_ARISTOTLE_PLATO_AND_SOCRATES_ANCIENT_GREEK_PERSPECTIVES_ON_EXPERIENTIAL_LEARNING"
            >
              [2]
            </a>
            . Aristotle even aimed his education ideology toward useful skills
            and abilities that would be practical in everyday life in Ancient
            Greece.
          </p>
          <p className="mb-4">
            The birth of more formal institutions followed, all mainly taking
            after Greek and Roman ideologies where in the medieval period,
            “universities” first became established
            <a
              className="text-sm"
              href="https://books.google.com/books?id=5Z1VBEbF0HAC"
            >
              [3]
            </a>
            . These were mostly religious-based entities that focused on
            theology, early medicine, and law
            <a
              className="text-sm"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2246620/"
            >
              [4]
            </a>
            . It's important to note that in these times, it was exclusively the
            wealthy that had access to these institutions as most other people
            were farmers, carpenters, manual laborers, etc. Economic mobility
            did not really exist as a concept in these times and no parents
            spent much time thinking about their children's future being
            president or as an astronaut like they do today. Their children
            would become exactly what the parents were: Farmers raised future
            farmers, simple as that.
          </p>
          <p className="mb-4">
            The purpose of education during this medieval period was to prepare
            wealthy aristocrats for positions in leadership, either in the
            government or within the church
            <a
              className="text-sm"
              href="https://www.tandfonline.com/doi/full/10.1080/03057240.2018.1444001"
            >
              [5]
            </a>
            . Education outside of the “wealthy-only” institutions was done as
            apprenticeship positions or was training that happened on the job,
            likely from birth. There was no right to education of any kind and
            you were either in a position of social power and accessed the
            elite, formal institutions to become a government official or
            chaplain, or you pursued a specific skill set in a life-long career
            in a subsistence role, like a farmer or carpenter.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={HonestWorkMeme}
            alt="It ain't much, but it's honest work meme"
          />
          <h2>Development: Here comes the Industrial Revolution!</h2>
          <p className="mb-4">
            Moving on to more modern times. Specifically in America, the
            ideology of democracy and the industrial revolution caused the next
            big shift in educational institutions. First, in order to keep up
            with the rapid growth in technology, a more skilled workforce was
            needed. Second, democratic ideology was influential in creating the
            “American Dream” and this shifted standardized and higher education
            from a wealthy luxury to a birthright between the early 19th century
            and the 20th century…unless you weren't white.
          </p>
          <p className="mb-4">
            This is where the “purpose” of education became more of an economic
            function that it had been historically. Beneficiaries of
            technological advancement like John D. Rockefeller encouraged
            education reform
            <a
              className="text-sm"
              href="https://www.cambridge.org/core/journals/history-of-education-quarterly/article/abs/john-d-rockefellers-general-education-board-and-the-rural-school-problem-in-the-midwest-19001930/C7C173576CEA3263FDBAD02B493994AA"
            >
              [6]
            </a>
            , mostly in order to better serve the industrial needs of the
            economy.
          </p>
          <p className="mb-4">
            Here, education became more democratized and its purpose became
            multifaceted. It needed to train common people for jobs within the
            economy, but historically was more of an intellectual-development,
            and preparatory tool for the future leaders of society. So while
            superior critical thinking skills and an element of “self-discovery
            for the benefit of society” might have been the initial idea behind
            formal education, the economy needed trained workers for jobs.
          </p>
          <p className="mb-4">
            This is still the prevailing function of education systems today: a
            split between an Aristotelian idea of creating intellectual, moral,
            and independent thinkers with a “Rockefeller-ian” twist on creating
            obedient, orderly workers who served a useful economic purpose. And
            this is where the crux of the issue lies today.
          </p>
          <img
            className="rounded-lg my-4 border border-black max-w-lg w-full h-auto"
            src={EducationSystemMeme}
            alt="Meme about the education system"
          />
          <h2>The Current Landscape: A Tale of Two Functions</h2>
          <p className="mb-4">
            We can see elements of both the prevailing ideologies within our
            K-12 and university systems now: Standardized tests as a tool to
            rank and assess the efficacy of students in different subjects is an
            obvious point for team Rockafeller. But we also still use a teaching
            style similar to that of Socrates. Reading a book, asking questions,
            and perhaps some lively discussion on its implications.
          </p>
          <p className="mb-4">
            The prevailing question is this: Is a formal education a tool for
            achieving wealth through a lucrative career within the existing
            economic system, or rather, is it a means of self-discovery and
            developing independent thinkers who question old ideas and pursue
            new ones independent of its commercialization potential? Do we solve
            for maximizing economic productivity, or for individual fulfillment,
            creative pursuit, and social well-being?
          </p>
          <p className="mb-4">
            To start, you might say that there is overlap; we can do both! While
            there probably is someone out there who loves doing your taxes, it
            probably does not include enough of the population to meet the
            demands of the economy. That too applies to many jobs. 80% of people
            say they are not passionate about their work
            <a
              className="text-sm"
              href="https://www.pewresearch.org/social-trends/2016/10/06/3-how-americans-view-their-jobs/"
            >
              [7]
            </a>{" "}
            and its very likely that the person reading this is not doing
            something they would do if money was out of the equation. So, while
            these ends aren't completely at odds: economic vs. social
            fulfillment, there is a strong argument to be made that neither one
            is done very well in the current system
            <a
              className="text-sm"
              href="https://fee.org/articles/the-failure-of-american-public-education/"
            >
              [8]
            </a>
            .
          </p>
          <img
            className="rounded-lg my-4 border border-black max-w-lg w-full h-auto"
            src={BoardRoomSchoolMeme}
            alt="Board Room Meme about the efficacy of the education system"
          />
          <h2>Issues with current systems: failure at both ends</h2>
          <p className="mb-4">
            The accessibility of public education has come a long way from the
            medieval times, but it fails to optimally achieve the balance of
            teaching useful technical skills for instrusty positions, alongside
            developing creative, independent, and ethical citizens.
          </p>
          <p className="mb-4">
            Employers are continually showing a lack of interest in many
            students that come out of higher education and students themselves
            realize this through increased difficulty finding a job that suits
            their academic backgrounds, not to mention their personal interests
            <a
              className="text-sm"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7351121/"
            >
              [9]
            </a>
            . Especially due to the increasing complexity of technology and the
            jobs that are available, there seems to be a growing discrepancy
            between what students are trained to do, and what they are being
            asked to do at their jobs, assuming they can even get one.
          </p>
          <p className="mb-4">
            Well, maybe then we are successfully helping young students find
            themselves and explore what they are passionate about, albeit at the
            expense of employability? Unfortunately, we are probably even
            farther away there. Schools report worsening engagement of all
            kinds, year after year
            <a
              className="text-sm"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8397605/"
            >
              [10]
            </a>
            <a
              className="text-sm"
              href="https://research.com/education/american-school-statistics"
            >
              [11]
            </a>
            . And the students themselves have never been more critical of
            education institutions and their failures.
          </p>
          <p className="mb-4">
            Additionally, the push toward STEM topics by parents, businesses,
            and universities alike, are emblematic of mis-matched economic
            demands and school functions. A shift of focus like this is fine in
            moderation, but if done to the detriment of shifting focus entirely
            away from topics that don't “make money”. There may not be a
            philosophy department left at any university in 20 years
            <a
              className="text-sm"
              href="https://dailynous.com/category/cuts-and-threats-to-philosophy-programs/"
            >
              [12]
            </a>
            . A failure to accommodate individual exploration, intellectual
            development, and self-discovery - could damage an economy far worse
            than a lack of software engineers. If nothing else, monumental
            changes in education solely for the benefit of the economy could
            compromise Western democracy entirely. Dramatic, but possible.
          </p>
          <p className="mb-4">
            Overwhelmingly, students are unfulfilled both in their studies and
            personal lives, with many relying on extracurricular means to
            acquire professional skills and social media platforms for
            self-discovery with school feeling like a waste of time.
          </p>
          <img
            className="rounded-lg my-4 border border-black max-w-lg w-full h-auto"
            src={STEMMeme}
            alt="Meme about STEM majors and the job market"
          />
          <h2>Interrogating Potential Solutions and Implications</h2>
          <p className="mb-4">
            Okay, now that we have sufficiently insulted the educational system
            on all fronts, let's talk solutions. Before that, what are potential
            solutions? Do we axe standardized testing in an effort to approach
            education in a less data-centric way, a human-centric approach where
            we can include nuance? But then how would we measure anything?
            Measuring growth, and the relative knowledge of students in a class
            is a necessary part of having structure and providing guidance,
            otherwise we wouldn't be sure if anyone learned anything at all.
          </p>
          <p className="mb-4">
            What about the public/private school discrepancy? Should all
            schooling be centralized and democratized? Or should public schools
            be axed and instead parents could choose among a free market style
            of educational institutions? Some would emulate traditional schools
            as we see them today, others might focus on apprenticeship-style
            skills, or others might be for creatives, or for celebrity elites
            (“Ye” West started a school: Donde Academy,{" "}
            <a href="https://en.wikipedia.org/wiki/Donda_Academy">FYI</a>)? This
            might result in a bigger mis-match between economic interests of
            companies that hire graduates of any level for work and what parents
            hypothesize on the “best” education for their child.
          </p>
          <p className="mb-4">
            In other words, how can we effectively balance the economic need for
            a certain number of jobs of each type (not everyone can be an
            influencer who graduates from YouTube University) and the interests
            of the individuals themselves and finding a purpose that is
            fulfilling, with sufficient room to explore? After all, some
            students are so focused and ingrained into the idea of getting a
            specific, high-paying job that they forget to explore what actually
            interests them, or never had a chance to explore in the first place
            (case in point: Me, and the reason I started{" "}
            <a href="https://www.bytesizelearning.org">ByteSize Learning</a>).
          </p>
          <p className="mb-4">
            It's clear a solution hasn't been put forth here yet and it may be
            an injustice to do so at all. This is a very complex issue and not
            one with a calculable number of variables. The economic, social, and
            individual interests are all at odds and there isn't an easy way or
            path to fixing it all at once. Not to worry though, we get a little
            closer to something in the next section.
          </p>
          <img
            className="rounded-lg my-4 border border-black max-w-lg w-full h-auto"
            src={WeWantAnswersMeme}
            alt="Meme from Anchorman about wanting answers from Steve Carrell"
          />
          <h2>Components of a successful system</h2>
          <p className="mb-4">
            Well, it is certainly overwhelming to attempt to tackle this all at
            once, so it might be easiest to start with some attributes of a
            successful educational system, in whatever final form they end up
            taking:
          </p>
          <p className="mb-4">
            First, the interests of the economy are always at play in a society
            of our size. This means that in one way or another, the jobs that
            keep our world running, at least those that exist after AI takes
            over, need to be filled with people who are skilled enough to do
            them, at a number that is in approximate harmony with demand. Again,
            there will have to be a balance of computer programmers/AI prompters
            with those who are responsible for filling potholes on the road.
          </p>
          <p className="mb-4">
            To do this, there must be some way of assessing students (future
            employees in this context) for their skills as they relate to
            potential jobs. Understanding economic needs will be important and
            curriculum of sorts that match job descriptions should also be
            considered. Whether this involves educational institutions working
            closer with industry and less of the government, or in some way
            privatizing and democratizing schools that better understand the job
            market needs to fulfill the demands of the economy. Somehow, we will
            have to distinguish between who is fit for the job, and who isn't,
            as unfortunate as that is, it is reality.
          </p>
          <p className="mb-4">
            Now, in order for education to fulfill the self-discovery function
            of education, it will be important that there is some component of
            their time and energy put purely toward exploratory topics. Grades
            and ranking systems here are pointless, effort might be the only
            useful metric, but the point of this isn't to decide who is best at
            what. The focus should be to encourage free-thought, not determine
            who is the most employable at whatever skill this is. Whatever
            students of any age decide to do in this exploratory time should not
            be held against them in the eyes of the job economy. In other words,
            not every 8-year old child will have a knack and undying passion for
            robotics and computer programming. Some will just like to play
            sports, draw, or debate for fun with no expectation of becoming Tom
            Brady, Leonardo Da Vinci, or Barack Obama. It is unrealistic and
            foolish to force students to pursue hobbies that have high utility
            by nature - as they will quickly abandon it given the chance.
          </p>
          <p className="mb-4">
            This time and effort is also ripe for developing social skills and
            making friends. It is much easier to make friends at any age with
            those who share interests or passions, and not those who scored the
            same on the test as you (you probably don't like them). Competition
            is not often conducive to symbiotic relationships - biology tells
            you that. This unstructured, loosely directed time should encourage
            new ideas and creative expression. It's possible that this allows
            for more innovation, even for economic benefit, but
            commercialization of new ideas is not the point here. The basic
            idea: Who cares if this makes money?
          </p>
          <p className="mb-4">
            Now the difficult part of all this is to decide how to structure
            this in a way that balances these two big concepts. A 50/50 split
            may not make many people happy, or be considered fair. And to be
            frank, many families who struggle financially might not have the
            luxury of their children and teenagers spending half their time
            “finding themselves” when there are bills to pay. This whole idea is
            based on the idea that people have some kind of choice when it comes
            to what to do with their lives, but this is not a reality for many.
            The ability to have any choice is a luxury in and of itself, but the
            pursuit to give people more choice is in society's best interest
            long term.
          </p>
          <img
            className="rounded-lg my-4 border border-black max-w-lg w-full h-auto"
            src={MathMeme}
            alt="Meme about math being hard and irrelevant in the education system"
          />
          <h2>An Unsatisfying Conclusion</h2>
          <p className="mb-4">
            If you were expecting a nicely packaged, “quick 3-step” solution for
            governments, businesses, and citizens to adopt in one fell swoop to
            solve all of our problems with the education system, unfortunately,
            you will not find that here. However, hopefully this can outline a
            few necessary directions for the collective to pursue, in whatever
            capacity they are able. In addition, more thought and discussion on
            changes to existing infrastructure can't hurt, especially as it
            seems no one is happy with the current system (except for maybe
            school administrators of private colleges that rake in money, oops -
            didn't mean to say that).
          </p>
          <p className="mb-4">
            Regardless, whoever you find yourself to be, either a student,
            teacher, employee, or senator; surely there is one action you could
            take toward improving some part of this issue. If you're a parent
            who insists on strapping your 4-year old child in front of Python
            script against their will, perhaps more freedom is what your child
            needs at an early age. Alternatively, if you're a high school art
            teacher harshly grading students on the history of Picasso, consider
            some other ways you might help students find careers in the art
            industry, or let those who are just fulfilling a graduation
            requirement have more fun in your class. They won't remember
            anything about Picasso anyway, but is that really the point?
          </p>
          <p className="mb-4">
            The point is, whoever you are and whatever influence you have (or
            lack), there is probably something you can do in your life to move
            the needle on education as an issue that is evergreen - AKA a
            problem that is not going anywhere anytime soon and since someone
            has to do something eventually. Why not you, right now?
          </p>
          <p className="mb-4">
            I'd love to keep the conversation going. Find me on{" "}
            <a href="https://www.linkedin.com/in/andrew-rust30/">LinkedIn</a> or
            shoot me an <a href="mailto:andrew@bytesizelearning.org">email</a>{" "}
            to grill me about what I am doing for the education system. Hint,
            It's{" "}
            <a href="https://www.bytesizelearning.org">ByteSize Learning</a>
          </p>
        </body>
      </article>
      <SharingBanner blogTitle={blogTitle} shareLink={shareLink} />
    </main>
  );
}

export default APRIL_7_2023;
