import React from "react";
import ByteSizeLogo from "../../assets/bytesize-logo.png";
// import { HomeIcon } from "@heroicons/react/24/solid";
import "flowbite-react";

function BlogNavigation() {
  return (
    <header>
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className=" xs:hidden sm:hidden md:grid grid-cols-3 items-center mx-auto max-w-screen-xl">
          <a
            href="/"
            className="flex flex-col items-center md:justify-center md:order-2 md:col-span-1"
          >
            <span className="self-center text-2xl whitespace-nowrap dark:text-white hover:text-dodger duration-200">
              <strong>ByteSize Blogs</strong>
            </span>
          </a>
          <div className="flex col-span-2 justify-end items-center md:order-3 md:col-span-1">
            <a
              href="#subscribe"
              className="md:block sm:hidden xs:hidden text-white bg-dodger hover:text-dodger border-2 border-dodger hover:bg-gray-100 focus:ring-4 focus:ring-dodger font-medium rounded-lg text-sm px-4 py-2 lg:py-2.5 mr-2 dark:bg-dodger dark:hover:bg-dodger focus:outline-none dark:focus:ring-dodger duration-200"
            >
              Subscribe to our newsletter!
            </a>
            <div></div>
          </div>
          <div>
            <a href="https://www.bytesizelearning.org/">
              <img
                src={ByteSizeLogo}
                className="mb-3 h-12 sm:h-18"
                alt="ByteSize Learning for kids and teens to learn tech skills Logo"
              />
            </a>
          </div>
        </div>
        <div className="md:hidden sm:flex justify-center">
          <a
            href="/"
            className="flex items-center justify-center"
          >
              <img
                src={ByteSizeLogo}
                className="mb-3 h-14"
                alt="ByteSize Learning for kids and teens to learn tech skills Logo"
              />
              <strong className="text-2xl ml-2">{" "}ByteSize Blogs</strong>
          </a>
        </div>
      </nav>
    </header>
  );
}

export default BlogNavigation;
