import React from "react";
import BlogHeader from "../UniversalBlogComponents/BlogHeader";
import SharingBanner from "../UniversalBlogComponents/SharingBanner";
import { Helmet } from "react-helmet";

import BusinessAsUsual from "../../assets/MARCH_24_2023Assets/BusinessAsUsual.mp4";
import SocietyIfMeme from "../../assets/MARCH_24_2023Assets/SocietyIfMeme.jpg";
import MetaverseMeme from "../../assets/MARCH_24_2023Assets/MetaverseMeme.jpg";
import SnapBackToReality from "../../assets/MARCH_24_2023Assets/SnapBackToReality.jpg";
import PowerResponsibility from "../../assets/MARCH_24_2023Assets/PowerResponsibility.gif";
import AIMeme from "../../assets/MARCH_24_2023Assets/AIMeme.jpg";
import sadDog from "../../assets/MARCH_24_2023Assets/sad-puppy.jpg";

import AIThumbnail from "../../assets/AIThumbnail.png";

function MARCH_24_2023() {
  const blogTitle = "Education, AI, and Astronaut Philosophers?";
  const blogSubtitle = "So you're wondering what the world looks like after AI has taken over, huh? Let's take a look at the future of AI and education from a March 2023 lens.";
  const blogAuthor = "Andrew Rust";
  const datePublished = "March 24th, 2023";
  const shareLink = "https://blogs.bytesizelearning.org/ai-and-the-future-of-education";

  return (
    <main className="pb-0 bg-white dark:bg-gray-900 items-center flex flex-col">
      
      <Helmet>
        <title>ByteSize Blogs | {blogTitle}</title>
        <meta name="description" content={blogSubtitle} />
        <meta
          name="keywords"
          content="K-12 Education, STEM Education, Creativity"
        />
        <meta name="author" content={blogAuthor} />
        <meta name="datePublished" content={datePublished} />
        <meta name="shareLink" content={shareLink} />
        <meta property="og:image" content={AIThumbnail}/>
      </Helmet>

      <BlogHeader
        datePublished={datePublished}
        shareLink={shareLink}
        blogTitle={blogTitle}
        blogSubtitle={blogSubtitle}
        blogAuthor={blogAuthor}
      />
      <article className="px-4 p-8 w-full max-w-screen-xl">
        <body className="prose-sm md:prose lg:prose-lg xl:prose-xl mx-auto max-w-none">
          <h1 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
            {blogTitle}
          </h1>
            <em className="text-xl">
              (I can assure you, ChatGPT did not write this article)
            </em>
          <p className="mb-4">
            Ah, Artificial Intelligence… The future of everything! All our
            life's problems either begin or end here
          </p>
          <p className="mb-4">
            I'm joking, of course. But seriously, AI seems like a pretty big
            deal. Let's talk about it.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={AIMeme}
            alt="Meme about AI being no match for the human brain and natural stupidity"
          />

          <p className="mb-4">
            Unless you have been living under a rock these past two years,
            (which might actually prove to be an appealing option after this AI
            boom resolves itself) you have probably seen the developments that
            have been made in artificial intelligence(AI) in one form or the
            other. The latest and greatest these AI tools have to offer is quite
            impressive, admittedly. If you haven't heard, here are some quick
            links to explore below on GPT4, the newest and shiniest AI-powered
            tool.
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <a
                href="https://twitter.com/Neo19890/status/1636035990057017346"
                target="_blank"
                rel="noopener noreferrer"
                className="text-dodger underline"
              >
                GPT4 makes a website from a hand drawing
              </a>
            </li>
            <li>
              <a
                href="https://www.iit.edu/news/gpt-4-passes-bar-exam"
                target="_blank"
                rel="noopener noreferrer"
                className="text-dodger underline"
              >
                GPT4 passing the bar exam
              </a>
            </li>
            <li>
              <a
                href="https://blog.duolingo.com/duolingo-max/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-dodger underline"
              >
                DuoLingoMAX
              </a>
            </li>
          </ul>
          <p className="mb-4">
            More specifically GPT4 is a LLM (Large Language Model) developed by{" "}
            <a
              href="https://openai.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dodger underline"
            >
              OpenAI
            </a>{" "}
            and there are many other developments in AI beyond just language
            models, however I will save those for another time.
          </p>
          <p className="mb-4">
            The links above show things that generally require seasoned
            professionals valuable keystrokes and years of domain knowledge to
            accomplish: but now it's accessible to everyone within a few clicks
            - quite amazing. So, after passing gradaute-level exams and building
            websites in minutes, it seems there won't be much left for students
            not to outsource to AI. But what about education as whole? Will all
            these breakthroughs lead us to a democratized utopian dream of
            education or are we on a path to taking robot-administered tests in
            the Metaverse, or is it all just a fad?
          </p>
          <p className="mb-4">
            Assuming we will see a paradigm shift at some point, when might that
            be? Did it already happen? 3 years? 20? Or will the educational
            system just limp along as it has, resisting change and embracing
            tradition? The answer to all these questions lie somewhere in the
            middle but let's take a moment to hypothesize a bit:
          </p>
          <p className="mb-4">
            In an effort to be unique in discussing AI's long-term impact on
            education as a whole, I'll attempt a glimpse into the future: the
            year 2050. Hopefully I avoid exciting or scaring anyone too much but
            I think day-dreaming can be a useful exercise to carry thoughts to
            their terminal position and allow for one to reorient themselves.
            something that might be helpful for students, educators, and
            everyone during this period of rapid change. To structure this a
            bit, here are four possible scenarios in which education may
            transformed by AI:
          </p>

          <ul className="list-disc list-inside mb-4">
            <li className="w-full sm:w-auto">
              <span className="md:text-2xl sm:text-lg font-bold text-gray-700">
                {" "}
                Scenario One:{" "}
              </span>
              Business as Usual
            </li>
            <li className="w-full sm:w-auto">
              <span className="md:text-2xl sm:text-lg font-bold text-gray-700">
                {" "}
                Scenario Two:{" "}
              </span>
              Astronaut Philosophers
            </li>
            <li className="w-full sm:w-auto">
              <span className="md:text-2xl sm:text-lg font-bold text-gray-700">
                {" "}
                Scenario Three:{" "}
              </span>{" "}
              AI Overlords in the Metaverse
            </li>
            <li className="w-full sm:w-auto">
              <span className="md:text-2xl sm:text-lg font-bold text-gray-700">
                {" "}
                Scenario Four:{" "}
              </span>
              Reality
            </li>
          </ul>

          <div className="mb-8 text-center sm:text-left mt-8">
            <h2 className="text-xl font-bold">
              <span className="text-gray-500">Scenario One: </span>
              Business As Usual
            </h2>
          </div>
          <video
            loop
            autoPlay
            className="rounded-lg my-4 max-w-lg w-full h-auto"
          >
            <source src={BusinessAsUsual} type="video/mp4" />
          </video>
          <p className="mb-4">
            All this hype for what? The year is 2050 and education from K-12 all
            the way up to the college system has chugged along. Maybe switching
            out textbooks for tablets, and paper tests for computer tests,
            and... Well basically just a bunch more screens, like we have
            already seen. But the system itself is all pretty much the same.
            Students attend the same schools for the same years, study the same
            subjects, and take the same exams. And behold: we get the same
            results! Students who are woefully equipped to handle life in the
            21st century. College is still the status quo for those who seek
            greener pastures, and while the system might fail on some levels, it
            succeeds on others. If it ain't totally broke, don't fix it, right?
          </p>
          <p className="mb-4">
            While this doesn't seem likely, there is an argument to be made in
            support of 'business as usual'. Does the first law of thermodynamics
            ring a bell? An object in motion will stay in motion, and yes; an
            education system at rest, will stay at rest.
          </p>
          <p className="mb-4">
            Alas, let us not dwell on this scenario, it is most certainly not
            the most exciting of the bunch.
          </p>

          <div className="mb-8 text-center sm:text-left mt-8">
            <h2 className="text-xl font-bold">
              <span className="text-gray-500">Scenario Two: </span>
              Astronaut Philosophers
            </h2>
          </div>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={SocietyIfMeme}
            alt="Society if school taught me how to pay my taxes meme"
          />
          <p className="mb-4">
            The year is 2050 and a revolutionized education system is an
            understatement. Students and teachers alike are no longer confined
            to the traditional classroom setting and learning is democratrized
            and hyper-personalized through the power of AI. All students have
            personalized AI assistants who help students stay on track, gather
            and analyze learning data and make adjustments in real-time. The
            'automated' functions of learning are balanced nicely with the
            irreplaceable human touch. Students are able to learn at their own
            pace, in their own way, and in their own time. Teachers and
            administrators are able to focus on different aspects of education,
            such as the social and emotional aspects of learning, and the more
            creative aspects of teaching that AI can't match.
          </p>
          <p className="mb-4">
            Immersified learning is made possible by continual improvements in
            computing power and VR/AR technology makes learning as engaging and
            fun as it can be. And the balance between student autonomy and AI
            assistance allows for a pace and variety of learning not thought
            possible in today's day and age. AI models are trained fairly
            (minimziing bias) and with privacy in mind.
          </p>
          <p className="mb-4">
            Overall; students achieve that healthy balance between time spent
            learning and time spent living. AI is now “man's best friend”. Sorry
            dogs, you had a good run.
          </p>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={sadDog}
            alt="Sad Dog pondering the future of education and AI"
          />
          <subtitle>Source: Unsplash</subtitle>

          <p className="mb-4">
            This idealized educational model would represent the utilitarian
            extreme with the only externality being too many creative problem
            solvers that outnumber the problems of our finite world, and thus we
            are doomed to become interplanetary philosophers debating higher
            meaning and pursuit of expanding human creativity.
          </p>
          <p className="mb-4">
            Pretty ridiculous hypothetical, but this is our upper bound. Now
            it's time to look at the lower bound... brace yourself.
          </p>

          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={MetaverseMeme}
            alt="Metaverse meme from the Simpsons"
          />
          <div className="mb-8 text-center sm:text-left mt-8">
            <h2 className="text-xl font-bold">
              <span className="text-gray-500">Scenario Three: </span>
              AI Overlords in the Metaverse
            </h2>
          </div>

          <p className="mb-4">
            It's 2050 and efficiency is the name of the game. The education
            system has been completely automated, privatized, and monetized.
            Students pay for the privilege of ever-improving "education
            perfomance algorithms" that are trained on their data and are
            designed to maximize their scores on standardized tests and newly
            invented "learning variables", largely decided by large institutions
            based on interests unknown to the public. Students are tracked day
            after day, hour after hour, and minute after minute towards
            optimizing their performance within constraint variables and the
            modalities, topics, and structure of learning is based on economic,
            political, and corporate interests. The education system is a
            machine, and the students are the cogs.
          </p>
          <p className="mb-4">
            Inequity expands as the ubiquity of "machine learning" learning
            naturally segment students into partitions under which they are
            categorized and serviced en mass. Students are no longer
            individuals, but rather a collection of data points that are used to
            inform and optimize an algorithm. Here, what you learn can only be a
            fraction of your effort, time, and ability. More accurately, what
            you are able to learn may be a function of a price tag: lucrative
            topics you may be interested in and access to high-branded
            institutions is barred by a paywall. Even so, education in this
            scenario is created by a team of developers tasked with automating
            education in the name of profit. Regulations to inhibit unfair
            efforts like this are limited and bias is rampant as agendas sneak
            their way into the curriculum.
          </p>
          <p className="mb-4">
            Maybe it is the Metaverse too, students are moved from physical
            classrooms into virtual ones through digital blindfolds while the
            lines between socializing, learning, and entertainment are blurred
            and optimized for maximal economic efficiency. Programs branded with
            "Students' well-being" in mind are analogous to the cereals branded
            as "healthy" (but still full of sugar and preservatives, check out{" "}
            <a
              href="https://theintercept.com/2023/03/01/fda-healthy-food-label-cereal-brands/"
              target="_blank"
              rel="noopener noreferrer"
            >
              this ongoing battle
            </a>{" "}
            right now)
          </p>
          <p className="mb-4">
            I won't dwell on this, but hopefully you get the picture. I truly
            hope this sounds ridiculous, but somehow it's not...
          </p>

          <div className="mb-8 text-center sm:text-left mt-8">
            <h2 className="text-xl font-bold">
              <span className="text-gray-500">Scenario Four: </span>Reality
            </h2>
          </div>
          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={SnapBackToReality}
            alt="Snap back to reality meme - Eminem"
          />

          <p className="mb-4">
            Here, we can average our thought experiments above and land on a few
            likely outcomes , while throwing the more dubious conclusions out.
            There will likely be some growing inequities between certain groups
            - we can speculate on what determining factors of that inequity will
            be (hint: $). There will also likely be growth of ML-based learning
            models that predict "optimal" learning outcomes much better than
            individual teachers or institutions could create. These applied in
            different learning environments could lead to very successful
            outcomes and others where pivots are made away from them. For these
            algorithms it will be especially important to consider
            non-mechanical factors such as social and emotional learning, and to
            stricly study and amend for bias. It will be important to balance
            any use of these models with human interaction, because left to its
            own devices, there is high risk of inequity. Either way,
            experimentation is already underway.
          </p>
          <p className="mb-4">
            The historical routines of lesson planning, instruction, and
            evaluation will find new “normals” as much of the leg work is
            automated with humans filling in the gaps. My guess is an 80/20
            split between technology/humans (I stand by the Pareto Principle).
            Our perceptions of traditional school may shift to areas less likely
            to be impacted and ideally we take more of a skill-based or
            project-based approach to learning and free up time for more
            creative pursuits, economic factors aside. The challenge might be in
            figuring out exactly what it is that we should be doing and how we
            maximize AI to our advantage.
          </p>
          <p className="mb-4">
            Some things don't change, many others do. Among a diversified
            learning environment, we see progress, problems, and perspectives
            that will have evolved but have made no progress toward complete
            uniformity - which is good. The healthy uncertainty we deal with
            everyday should continues as if AI changed nothing at all, just the
            words, mediums, and strategies we use to discuss them. The moment we
            have no uncertainty, no problems, and no room for discourse is the
            moment we have lost our humanity.
          </p>
          <p className="mb-4">
            If this sounds vague, it should, because there isn't a great way to
            predict any future, much less a future impacted by AI. But if we
            first consider extremes, we are more likely to establish a feasible
            range where predictions might be more accurate.
          </p>

          <h2 className="mb-4 text-2xl dark:text-white font-extrabold leading-none text-gray-900">
            Conclusion
          </h2>
          <p className="mb-4">
            While we have lots to be excited about, we certainly have much more
            to be catious of as well. Said best by Uncle Ben:
          </p>

          <img
            className="rounded-lg my-4 max-w-lg w-full h-auto"
            src={PowerResponsibility}
            alt="Unlce Ben Meme: with great power comes great responsibilty"
          />

          <p className="mb-4">
            The most important thing to remember is that AI is not a panacea,
            nor a singularity. It is a tool, and like all tools, it holds high
            potential for both good and evil. The potential for bias and misuse
            of algorithms is already frightening, so it will be important to
            move forward with caution as a collective while allowing the
            boundaries of science to be pushed. There is never a time to panic,
            but it is always good to be vigilant.
          </p>

          <p className="mb-4">
            Of course, this is just my opinion, and I am sure I haven't said
            much that hasn't already been said, but I hope you found these
            hypotheticals interesting and that they have given you some
            perspective on remaining optimistic but cautious.
          </p>
          <p className="mb-4">
            If you have any questions or comments, please feel free to reach out
            to me via{" "}
            <a
              href="mailto:andrew@bytesizelearning.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email
            </a>{" "}
            or{" "}
            <a
              href="https://www.linkedin.com/in/andrew-rust30/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            .
          </p>
        </body>
      </article>
      <SharingBanner datePublished={datePublished} shareLink={shareLink} />
    </main>
  );
}

export default MARCH_24_2023;
