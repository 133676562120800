import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import MARCH_24_2023 from './components/BlogPosts/MARCH_24_2023.jsx';
import APRIL_7_2023 from './components/BlogPosts/APRIL_7_2023.jsx';
import MAY_5_2023 from './components/BlogPosts/MAY_5_2023.jsx';
import JUNE_2_2023 from './components/BlogPosts/JUNE_2_2023.jsx';
import JUNE_30_2023 from './components/BlogPosts/JUNE_30_2023.jsx';
import AUGUST_11_2023 from "./components/BlogPosts/AUGUST_11_2023.jsx";
import SEPTEMBER_1_2023 from "./components/BlogPosts/SEPTEMBER_1_2023.jsx";
import OCTOBER_6_2023 from "./components/BlogPosts/OCTOBER_6_2023.jsx";
import NOVEMBER_7_2023 from "./components/BlogPosts/NOVEMBER_7_2023.jsx";
import FEBRUARY_15_2024 from "./components/BlogPosts/FEBRUARY_15_2024.jsx";
import MAY_27_2024 from "./components/BlogPosts/MAY_27_2024.jsx";

import BlogNavigation from './components/UniversalBlogComponents/BlogNavigation.jsx';
import BlogFooter from './components/UniversalBlogComponents/BlogFooter.jsx';

import"flowbite-react";
import ReactGA4 from "react-ga4";
import BlogsHome from "./components/BlogsHome.jsx";


ReactGA4.initialize("G-D7VRZQXM1Q");

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    console.log("Page View on " + location.pathname + "" + window.location.href);
    ReactGA4.send("page_view", {
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location]);
}


function PageViewTracker() {
  usePageViews();
  return null;
}

function App() {
  console.log("App Rendered")
  return (
    <Router basename="/">
      <PageViewTracker />
      <BlogNavigation />
      <Routes>
        <Route path="/ai-and-the-future-of-education" element={<MARCH_24_2023 />} />
        <Route path="/what-is-the-purpose-of-education-in-2023" element={<APRIL_7_2023 />} />
        <Route path="/creativity-unraveled-and-the-power-of-the-spontaneous" element={<MAY_5_2023 />} />
        <Route path="/exploring-a-digital-literacy-license-in-2023" element={<JUNE_2_2023 />} />
        <Route path="/xai-should-be-higher-focus-than-agi-in-2023" element={<JUNE_30_2023 />} />
        <Route path="/the-problem-of-abstracting-technical-knowledge" element={<AUGUST_11_2023 />} />
        <Route path="/old-school-vs-new-school-education-topics" element={<SEPTEMBER_1_2023 />} />
        <Route path="/triad-of-technology-education" element={<OCTOBER_6_2023/>} />
        <Route path="/gamified-learning-and-its-consequences" element={<NOVEMBER_7_2023 />} />
        <Route path="/floss-in-technology-education" element={<FEBRUARY_15_2024 />} />
        <Route path="/blogs/techno-philosophy" element={<MAY_27_2024 />} />
        <Route path="/" element={<BlogsHome />} />
        <Route path="*" element={<BlogsHome />} />
      </Routes>
      <BlogFooter />
    </Router>
  );
}

export default App;
